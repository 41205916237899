import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Canvas from '../components/p5/p5wrapper';
import css from '../styles/404.module.scss';

export default function ErrorPage(): JSX.Element {
    return (
        <Layout>
            <SEO title="404" 
                 desc="Whatever you are looking for doesn't exist" />
            <div className={css.container}>
                <h1>404 Error</h1>
                <p>Unfortunately, what you are looking for does not exist.</p>
            </div>
        </Layout>
    )
}